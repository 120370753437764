// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "* {\n  box-sizing: border-box;\n\n}\n\n.M6n\\+ohASvZQWjPTvlxfRSQ\\=\\= {\n  display: flex;  \n  flex-direction: column;\n  justify-content: flex-start;\n  padding: 10px;\n  background-color: var(--medium-green-alpha);\n  border: solid var(--dark-green) 2, 0, 2, 0;\n}\n\n@media screen and (min-width: 415px) {\n\n.M6n\\+ohASvZQWjPTvlxfRSQ\\=\\= {\n    display: none\n}\n  }\n\n.udgIgHpFma\\+hYOyGa--67A\\=\\= {\n  width: 95vw;\n  margin-left: -3em;\n\n}\n\n._4EWZ2AOtN6mmDr9ZnNZ4zQ\\=\\= {\n  margin-top: -30px;\n  margin-left: 1.5em;\n  font-size: 1.5em;\n  font-family: 'Goldman';\n  color: var(--white);\n  text-shadow: var(--dark-green) 2px 2px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/between-bars/PhoneBar.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;;AAExB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,aAAa;EACb,2CAA2C;EAC3C,0CAA0C;AAK5C;;AAHE;;AARF;IASI;AAEJ;EADE;;AAGF;EACE,WAAW;EACX,iBAAiB;;AAEnB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,sBAAsB;EACtB,mBAAmB;EACnB,sCAAsC;AACxC","sourcesContent":["* {\n  box-sizing: border-box;\n\n}\n\n.bar {\n  display: flex;  \n  flex-direction: column;\n  justify-content: flex-start;\n  padding: 10px;\n  background-color: var(--medium-green-alpha);\n  border: solid var(--dark-green) 2, 0, 2, 0;\n\n  @media screen and (min-width: 415px) {\n    display: none;\n  }\n}\n\n.logo {\n  width: 95vw;\n  margin-left: -3em;\n\n}\n\n.section {\n  margin-top: -30px;\n  margin-left: 1.5em;\n  font-size: 1.5em;\n  font-family: 'Goldman';\n  color: var(--white);\n  text-shadow: var(--dark-green) 2px 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bar": "M6n+ohASvZQWjPTvlxfRSQ==",
	"logo": "udgIgHpFma+hYOyGa--67A==",
	"section": "_4EWZ2AOtN6mmDr9ZnNZ4zQ=="
};
export default ___CSS_LOADER_EXPORT___;
