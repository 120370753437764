// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fezJmIQZg0zcSwcOWLzCmQ\\=\\= {\n  position: absolute;\n  top: 90vh;\n}\n", "",{"version":3,"sources":["webpack://./src/components/amphead/Notification.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;AACX","sourcesContent":[".notificationBox {\n  position: absolute;\n  top: 90vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notificationBox": "fezJmIQZg0zcSwcOWLzCmQ=="
};
export default ___CSS_LOADER_EXPORT___;
